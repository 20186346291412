export const constant = {
    MIXPANEL_CREATE_PAYMENT_LINK_WALLET: 'Create Payment Link - WalletPayment',
    MIXPANEL_CREATE_PAYMENT_LINK_FREECOURSE: 'Create Payment Link - FreeCourse',
    MIXPANEL_CREATE_PAYMENT_LINK_NON_RAZORPAY: 'Create Payment Link - NonRazorpay',
    MIXPANEL_CREATE_PAYMENT_LINK: 'Create Payment Link',
    MIXPANEL_CHANGE_COURSE: 'Course Change',
    MIXPANEL_CHANGE_TEACHER: 'Change Teacher',
    MIXPANEL_CANCEL_CLASS: 'Cancel Class',
    MIXPANEL_TEACHER_NOSHOW_DELETE: 'Teacher Noshow Delete',
    MIXPANEL_AE_SLOT_ADD: 'AE Slot Add',
    MIXPANEL_AE_SLOT_DELETE: 'AE Slot Delete',
    MIXPANEL_SLOT_ADD_TEACHER: 'Slot Add',
    MIXPANEL_SLOT_DELETE_TEACHER: 'Slot Delete',
    // MIXPANEL_CLASS_NOSHOW : 'Slot Noshow',
    MIXPANEL_ENABLE_FOR_PAID: 'Enable for Paid',
    MIXPANEL_CANCEL_CLASS_TEACHER: 'Cancel Class',
    MIXPANEL_CLASS_SCHEDULE: 'Class Schedule',
    MIXPANEL_SLOT_CANCEL: 'Slot Cancel',
    MIXPANEL_SLOT_COMPLETE: 'Class Complete',
    MIXPANEL_TEACHER_WORKING_HOURS_ADD: 'Teacher Working Hours Add',
    MIXPANEL_MARK_FOR_PENALTY: 'Mark For Penalty',
    MIXPANEL_MARK_AS_JOIN: 'Mark As Join',
    MIXPANEL_LEAVE_APPROVE: 'Leave Approve',
    MIXPANEL_LEAVE_WITHDRAWAL: 'Leave Withdrawal',
    MIXPANEL_LEAVE_REJECT: 'Leave Reject',
    MIXPANEL_LEAVE_EDIT: 'Add Leave Balance',
    MIXPANEL_REFERRAL_APPROVE: 'Referral Approve',
    MIXPANEL_REFERRAL_REJECT: 'Referral Reject',
    MIXPANEL_MOVE_TO_ARTIUM_LIBRARY: 'Move To Artium Library',
    MIXPANEL_MOVE_TO_ASSIGNMENT_LIST: 'Move To Assignment List',
    MIXPANEL_DELETE_FROM_ASSIGNMENT_LIST: 'Delete From Assignment List',
    MIXPANEL_CREATE_OFFLINE: 'Create Group For Offline',
    MIXPANEL_OFFLINE_STUDENT_REGISTRATION: 'Offline Student Registration',
    MIXPANEL_OFFLINE_STUDENT_DELETION: 'Offline Student Delete',
    MIXPANEL_OFFLINE_BATCH_DELETION: 'Offline Batch Delete',
    MIXPANEL_PREFERRED_DAY_TIME: 'Update Preferred Day & Time',
    MIXPANEL_DEMOOPS_STATUS_CHANGE: 'DemoOps-Agent Status Change',
    MIXPANEL_DEMOOPS_TOGGLE_BUTTON_CHANGE: 'DemoOps-Agent Status-Toggle-Button Change',
    MIXPANEL_DEMOOPS_STATUS_API : 'DemoOps-Agent Status-API',
    MIXPANEL_OFFLINE_GROUP_DETAIL_UPDATE_API : 'Offline Group detail update-API',
    MIXPANEL_TEACHER_ESCALATION : 'Teacher Escalation Update',
    MIXPANEL_TEACHER_PIP : 'Teacher Pip Update',
    MIXPANEL_STUDENT_MEMBERSHIP : 'Student Membership Update'

};

